$(document).on('click', '.advanced-search', function () {
    // rgb(219, 48, 105) #672268
    // rgb(103, 34, 104) #db3069
    let current_color = $(this).css('color');
    current_color =
        current_color == 'rgb(219, 48, 105)' ? 'rgb(103, 34, 104)' : 'rgb(219, 48, 105)';
    let rotation = current_color == 'rgb(103, 34, 104)' ? 'unset' : 'rotate(180deg)';
    // Show the color itself
    $('.advanced-search').css('color', current_color);
    $('.advanced-search').find('img').css('transform', rotation);
});
